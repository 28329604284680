.container {
  pointer-events: visible;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.200691));
  z-index: 1000;
  border-radius: 8px;
  background: white;
  color: black;
}

.arrowContainer {
  position: relative;
  /* height: 10px; */
}

.arrow {
  position: absolute;
  transform: translateY(-5px) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: white;
}

.body {
  padding: 8px 6px 8px 6px;
  display: flex;
  flex-direction: column;

  font-family: Poppins, Roboto, Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  white-space: nowrap;
  text-align: initial;
}

.line {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  padding: 4px 12px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  color: #848199;
  opacity: 0.7;
  /* cursor: default; */
  pointer-events: none;
}

.line:hover:not(.disabled) {
  background-color: #f4f7fa;
}

/* Turn the white icon to black */
.line:not(.highlighted) .iconContainer {
  filter: invert(100%);
}

/* Turn the white icon to the disabled color #848199 */
.line.disabled .iconContainer > img {
  filter: invert(22%) sepia(6%) saturate(522%) hue-rotate(205deg) brightness(87%) contrast(85%);
}

.body > hr {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  border: 0.86px solid #ebedf3;
}

.emptySpace {
  width: 17px;
}

.upgradeLink {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #7517f8;
  cursor: pointer;
}
