.tag,
.tagInput {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.165px;
  letter-spacing: 0.219px;
  height: 26px;
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  background: #e6f1ff;
  white-space: nowrap;
  flex: 0 0 auto;
}

.rounded {
  border-radius: 6px;
}

.noThumbnail {
  padding-left: 10px;
}

.tag:hover {
  cursor: pointer;
  background: #d1e3ff;
}

.tag.invalid {
  background: #fdd5d3;
  padding-left: 10px;
}

.tag.focused {
  background: #d1e3ff;
}

.tag.invalid.focused {
  background: #fcc3c0;
}

.tag.invalid:hover {
  background: #fcc3c0;
}

.tag.invalid.highlight {
  background: #fdd5d3;
  animation: shake 0.2s;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }

  50% {
    transform: translate(-1px, -1px);
  }

  100% {
    transform: translate(1px, 1px);
  }
}

.editableTagContainer {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  height: 26px;
  background: #e6f1ff;
  white-space: nowrap;
  flex: 0 0 auto;
  position: relative;
}

.tagInput {
  background: transparent;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  border: none;
  outline: none;
  width: 100%;
  min-width: 0;
}

.tagInput:focus {
  background: transparent;
  padding: 0;
  border-radius: 0;
}

.editableTagContainer:focus-within {
  background: #d1e3ff;
}

.editableTagContainer.invalid {
  background: transparent;
}

.editableTagContainer.invalid:focus-within {
  background: transparent;
}

.tagInput.invalid {
  background: transparent;
}

.tagInput.invalid:focus {
  background: transparent;
  padding: 0;
  border-radius: 0;
}

.hiddenSpan {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-family: inherit;
  white-space: pre;
  padding: 2px 8px;
}

.thumbnail {
  width: 16px;
  height: 16px;
  object-fit: cover;
  object-position: center;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 50%;
}

.closeIcon {
  margin: 0 5px;
  width: 8px;
  height: 8px;
}

.closeIcon:hover path {
  stroke: #113357;
}

.closeButton {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background: none;
  border: none;
  cursor: pointer;
  margin: -2px;
}

.closeButton:hover path {
  stroke: #113357;
}
