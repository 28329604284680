.profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile:hover {
  cursor: pointer;
}

.profile img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.border img {
  border: 2px solid #f5b53d;
}

.teamMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
  border: 1px solid #ebedf3;
  border-radius: 6px;
  background: #fff;
  padding: 10px;
  width: 224px;
  margin-right: 20px;
}

.item {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 12px;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.item svg,
.item img {
  width: 16px;
  height: 16px;
  justify-self: center;
}

.item:hover {
  cursor: pointer;
  background: #e6f1ff;
  border-radius: 4px;
}

.thumbnail {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.settings>svg {
  width: 22px;
  height: 22px;
}