.closeIcon {
  position: absolute;
  cursor: pointer;
  color: #848199;
  top: 13px;
  right: 13px;
  width: 16px;
  height: 16px;
}

.closeIcon:hover {
  color: #c2c3c9;
}

.deleteContainer {
  width: 500px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.200691);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.deleteContainer .title {
  color: #113357;
  text-align: center;
  font-family: Sora;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.152px;
}

.deleteContainer .subtitle {
  color: #113357;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.2px;
  letter-spacing: -0.125px;
}

.deleteContainer .buttonsContainer {
  display: flex;
  gap: 20px;
}