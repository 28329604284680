.container {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  padding: 6px;
  margin-bottom: 6px;
  min-width: 0;
}

.container:last-child {
  margin-bottom: 0;
}

.container:hover {
  background-color: #e9eaef;
}

.container.selected {
  background-color: #e6f1ff;
}

.rounded {
  border-radius: 6px;
}

.avatar {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  min-width: 22px;
  min-height: 22px;
  max-width: 22px;
  max-height: 22px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  min-width: 0;
}

.name {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.219px;
  white-space: nowrap;
}

.email {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.219px;
  min-width: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.verticalDivider {
  color: #cbcfd2;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.219px;
}
