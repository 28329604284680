.usersContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  max-height: 168px;
  background: #fff;
  border: 1px solid #dadce0;
  border-top: none;
  z-index: 1000;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rounded {
  border-radius: 0 0 6px 6px;
}

.userLineSkeleton {
  width: 100%;
  height: 34px;
}

.inviteUsersSelectContainer {
  position: relative;
}
