.modalTextLine {
  color: #0b2642;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.213px;
}

.bulkPermissionMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 113px;
  border: 1px solid #ebedf3;
  border-radius: 6px;
  background: #fff;
  padding: 1px;
  gap: 2px;
  justify-content: space-around;
}

.permissionMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 128px;
  background: #fff;
  padding: 7px;
  gap: 2px;
  justify-content: space-around;
}

.item {
  width: 100%;
  height: 28px;
  padding: 9px 12px;
  color: #123864;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.item:hover {
  cursor: pointer;
  background: #e6f1ff;
  border-radius: 6px;
}

.name {
  color: #113357;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25.612px;
}

.bold {
  font-weight: 600;
}

.selectedPermission {
  background: #e6f1ff;
  border-radius: 6px;
}

.permissionDropDown {
  width: 100%;
  height: 32px;
  fill: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 17px;
}

.permissionDropDown:hover {
  cursor: pointer;
  opacity: 1;
}

.permissionDropDown > span {
  color: #113357;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
}

.permissionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
}
