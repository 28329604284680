.container {
  display: inline-flex;
  width: 100%;
  align-items: center;
  gap: 15px;
  width: 400px;
}

.container img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: inherit;
  justify-content: flex-start;
  min-width: 0;
}

.nameContainer {
  display: flex;
  gap: 9px;
  max-width: inherit;
  align-items: center;
}

.name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #113357;
  min-width: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email {
  font-family: "Poppins", serif;
  font-size: 12px;
  font-weight: 400;
  color: #848199;
}

.free {
  background: #ff7a53;
  width: 38px;
  height: 17px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0.219px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
