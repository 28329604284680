.container {
  position: relative;
  width: 257px;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  box-shadow: 0px 10px 10px rgba(111, 114, 132, 0.2), 0px 10px 10px rgba(111, 114, 132, 0.2),
    0px 5px 4px rgba(111, 114, 132, 0.2), 0px 2px 2px rgba(111, 114, 132, 0.1);
  border-radius: 8px;
}

.arrow svg {
  box-shadow: -4px 4px 6px 3px rgba(0, 0, 0, 0.05);
}

.image {
  height: 133px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px 8px 0 0;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: #113357;
  color: white;
  padding: 18px;
  border-radius: 0 0 8px 8px;
}

.miniTitle {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0.6em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.5;
}

.title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
}

.description {
  margin-top: 3px;
  margin-bottom: 10px;
  color: #a0a2b1;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skipButton {
  color: #5a9bff;
  font-family: Poppins, Roboto, Arial;
  font-size: 12px;
}

.skipButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.closeIcon {
  position: absolute;
  top: 14px;
  right: 14px;
  transform: translate(-2%, -2%);
  z-index: 500;
  height: 14px;
  width: 14px;
  pointer-events: auto;
  color: #848199;
}

.closeIcon:hover {
  color: #c2c3c9;
  cursor: pointer;
}

.walkthroughPlayButton {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(25, 115, 255, 0.8);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.walkthroughPlayButton:hover {
  background-color: rgba(25, 115, 255, 1);
}

.walkthroughPlayButton svg {
  margin-left: 3px;
}

.walkthroughPlayButton svg path {
  fill: white;
}
