.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 36px;
  width: 100%;
  padding: 4px 5px;
  max-height: 167px;
  overflow-y: auto;
  gap: 3px;
  background: #fff;
  align-items: center;
  font-family: inherit;
  scroll-behavior: smooth;
  cursor: text;
  border: 1px solid #cbcfd2;
  box-sizing: border-box;
}

.container [data-lastpass-icon-root] {
  display: none !important;
}

.container [data-lastpass-infield="true"] {
  display: none !important;
}

.containerDropdownOpen {
  border-radius: 6px 6px 0 0;
}

.rounded {
  border-radius: 6px;
}

.input {
  min-width: 5px;
  width: auto;
  flex: 0 0 auto;
  padding: 0;
  outline: none;
  border: none;
  font-size: 14px;
  color: #113357;
  font-family: inherit;
  font-weight: normal;
  line-height: normal;
}

.hiddenMeasureSpan {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  font-family: inherit;
  white-space: pre;
  padding: 0;
  height: 0;
  pointer-events: none;
}
