.wrapper {
  position: absolute;
  top: 50px;
  right: 2px;
  width: 276px;
  background-color: rgb(17, 51, 87);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 10px;
  padding: 15px;
  min-height: 137px;
}

.titleWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  color: white;
  font-size: 16px;
  font-family: Poppins;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 75px;
  margin-top: 5px;
}

.content {
  color: rgb(160, 162, 177);
  font-size: 12px;
  font-family: Poppins;
  padding-left: 30px;
}

.join {
  align-self: end;
  background: #1973ff;
  border: 0px;
  color: white;
  border-radius: 6px;
  height: 28px;
  width: 80px;
  cursor: pointer;
}
