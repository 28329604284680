.container {
  width: 517px;
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.3);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.topContainer {
  padding: 25px 25px 20px 25px;
}

.editName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  gap: 10px;
}

.editName > span {
  color: #113357;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nameInput {
  all: unset;
  width: 73%;
  border: 1px solid #dadce0;
  height: 32px;
  color: #113357;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.307px;
  padding-left: 8px;
}

.collaborators {
  padding: 20px 20px 20px 22px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 20px;
  height: 302px;
  overflow-y: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-top: 1px solid #ebedf3;
}

.deleteAndLeaveFooter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid #ebedf3;
}

.userLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 6px;
}

.userLine:hover {
  background: #e6f1ff;
}

.userLineSkeleton {
  width: 100%;
  height: 100%;
  margin-bottom: -2rem;
}

.checkListLine:hover {
  cursor: pointer;
  background: #e6f1ff;
}

.listUser {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
}

.checkListUser:hover {
  cursor: pointer;
  opacity: 0.5;
}

.checkListUser > input:hover {
  cursor: pointer;
}

.noMembersToAdd {
  background: #f2f8ff;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  width: 440px;
  flex-direction: column;
  align-self: center;
}

.noMembersToAdd > span {
  width: 407px;
  height: 36.263px;
  color: #1973ff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.219px;
}

.deleteButton {
  background-color: white;
  border: 1px solid rgb(224, 62, 26);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 7px;
  height: 32px;
}

.deleteButton > span {
  color: rgb(224, 62, 26);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 13.165px;
  letter-spacing: 0.219px;
}

.deleteButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.manageButton {
  all: unset;
  background: #f4f7fa;
  color: #113357;
  text-align: center;
  font-family: "Sora";
  font-size: 14px;
  font-weight: 400;
  width: 78px;
  height: 32px;
}

.manageButton:hover {
  cursor: pointer;
  opacity: 0.5;
}

.wideButton {
  width: 131px;
}

.permissionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
  padding: 20px 0;
}

.memberMenuDropDown {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.memberMenuDropDown:hover {
  cursor: pointer;
  opacity: 1;
}

.memberMenuDropDown > span {
  color: #113357;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
}

.memberMenuArrow {
  background: #ffffff;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  left: 74px;
  top: -6px;
  border-left: 1px solid #ebedf3;
  border-top: 1px solid #ebedf3;
}

.memberMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ebedf3;
  background: #fff;
  padding: 10px;
  justify-content: space-around;
}

.memberMenu .item {
  width: 80px;
  height: 28px;
  padding: 2px;
  color: #123864;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menuIcon {
  width: 17px;
  height: 7px;
  background: #848199;
  -webkit-mask: url(/images/menu-icon.svg) no-repeat center;
}

.inviteField {
  background: #f8fafd;
  padding: 16px 0;
  min-height: 98px;
}

.inviteField > span {
  padding-left: 16px;
  color: #113357;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fieldContainer {
  min-height: 32px;
  padding: 0 13px;
}

.emailInput {
  width: 224px;
  flex-grow: 5;
  padding-top: 3px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #dadce0;
  padding: 0 20px;
  align-self: center;
}

.invitePermissionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.membersPermissionDropdown {
  background: white;
  border: 1px solid #dadce0;
}

.permission {
  padding: 2px;
  color: #123864;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: end;
}

.arrow {
  background: #ffffff;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
  position: absolute;
  left: 101px;
  top: -6px;
  border-left: 1px solid #ebedf3;
  border-top: 1px solid #ebedf3;
}

.search {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.searchContainer {
  border: 1px solid #1973ff;
  height: 32px;
}

.searchInput {
  width: 100%;
  padding: 6px 9px 5px;
  border: none;
  color: #848199;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.219px;
}

.searchInput:focus {
  outline: none;
}

.subtitle {
  color: #113357;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.213px;
}

.userPermission {
  width: 100px;
  text-align: start;
  display: flex;
  justify-content: center;
}

.flexStart {
  justify-content: flex-start;
}

.narrowDropdown {
  width: 70px;
}

.invite {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subtitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manageSeats {
  color: #1973ff;
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.165px;
  letter-spacing: 0.219px;
  text-decoration-line: underline;
  align-self: flex-end;
}

.manageSeats:hover {
  cursor: pointer;
  opacity: 0.5;
}
